import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Search from '../components/search'
import { renderPostsList, renderTags } from '../utils'

const Tag = ({ pageContext: { tag }, data }) => {
  const { totalCount } = data.allMarkdownRemark
  const posts = data.allMarkdownRemark.edges
  const tagHeader = `${totalCount} artículo${totalCount === 1 ? "" : "s"} en la categoría `

  return (
    <Layout title={`Categoría ${tag}`}>
      <div className="main-content post-list-main mt-3 p-3">
        <h1 className="heading">
          {tagHeader}
          {renderTags([tag], tag)}
        </h1>
        <section id="search">
          <Search indices={[{ name: `Blog`, title: `Artículos` }]} />
        </section>
        {renderPostsList(posts)}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      totalCount
      edges {
         node {
            excerpt(pruneLength: 200)
            id
            frontmatter {
                title
                date
                tags
            }
             fields {
                slug
            }
        }
      }
    }
  }
`

export default Tag